<!-- 【提成绩效】设置 -->
<template>
  <div class="container">
    <div class="form-overflow">
      <div class="title-container">
        <div class="a">
          <span @click="$router.push('/portrait/performance')" class="a">绩效设置</span><span class="b">/</span> <span class="s">设置</span>
          <!-- <span class="c"
            >【提成绩效】客服当月绩效=当月产能*客服基础提成系数+（当月产能-基础提成临界值）*客服基础提成系数*超出区间提成系数</span
          > -->
        </div>
      </div>
      <div class="body">
        <div class="head">提成绩效</div>
        <el-form ref="form" :model="form" label-width="150px">
          <el-form-item prop="criticalValue" label="产能临界值">
            <el-input
              v-model="form.criticalValue"
              type="number"
              min="0"
              @input="
                (val) => {
                  iptChange(val, 'a');
                }
              "
              class="ipt"
            ></el-input>
            <span class="y">元</span>
          </el-form-item>
          <el-form-item prop="basicCommission" label="客服基础提成系数">
            <el-input
              v-model="form.basicCommission"
              type="number"
              min="0"
              @input="
                (val) => {
                  iptChange(val, 'b');
                }
              "
              class="ipt"
            ></el-input>
          </el-form-item>
          <el-form-item prop="" label="基础提成临界值">
            <el-input v-model="base" disabled class="ipt"></el-input>
          </el-form-item>
          <el-form-item prop="" label="提成区间">
            <!-- 遍历 -->
            <div v-for="(item, i) in form.detailList" :key="i">
              <template v-if="item.deleteFlag != 1">
                <el-input
                  v-model="item.startPrice"
                  type="number"
                  disabled
                  class="ipt"
                ></el-input>
                <span class="zhi">至</span>
                <el-input
                  @change="
                    (val) => {
                      changeNum(val, i);
                    }
                  "
                  v-model="item.endPrice"
                  type="number"
                  min="0"
                  @input="
                    (val) => {
                      iptChange(val, 'c');
                    }
                  "
                  class="ipt"
                ></el-input>
                <span class="xi">超临界值区间系数</span>
                <el-input
                  v-model="item.advancedCommission"
                  type="number"
                  min="0"
                  @input="
                    (val) => {
                      iptChange(val, 'd');
                    }
                  "
                  class="ipt"
                ></el-input>
                <el-button
                  @click="del(i)"
                  v-if="i != 0"
                  icon="el-icon-delete"
                  class="del"
                  circle
                ></el-button>
              </template>
            </div>
            <el-button
              @click="
                () => {
                  addList();
                }
              "
              plain
              icon="el-icon-plus"
              class="cp"
              >新增区间</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bottom-tip">
      <div>【提成绩效计算】<br/>&nbsp;<br/></div>
      <div>
        当实际产能≤产能临界值时，客服当月绩效=当月产能*客服基础提成系数<br/>
        当实际产能＞产能临界值时，客服当月绩效=当月产能*客服基础提成系数+（当月产能-基础提成临界值）*客服基础提成系数*超出区间提成系数
      </div>
    </div>
    <div class="footer-container">
      <div class="btn-content">
        <el-button
          @click="
            () => {
              $router.push('/portrait/performance');
            }
          "
          class="common-screen-btn"
          >取消</el-button
        >
        <el-button
          @click="
            () => {
              addSet();
            }
          "
          type="primary"
          class="common-screen-btn"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { add, detail, edit } from "../../service/portrait.js";
import { Config } from "../../utils/index.js";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      form: {
        criticalValue: "", //产能临界值
        basicCommission: "",
        detailList: [
          {
            id: null,
            startPrice: null,
            endPrice: null,
            advancedCommission: null,
            deleteFlag: null
          }
        ]
      },
      params: {}, //传过来的
      params2: {}, //表格传过来的
      errorNum: false,
      noNum: false,
      companyId: this.$sto.get(Config.constants.userInfo).company_id
    };
  },
  //监听属性 类似于data概念
  computed: {
    base() {
      return this.form.criticalValue * this.form.basicCommission;
    }
  },
  //监控data中的数据变化
  watch: {
    base(val) {
      if (val) {
        this.form.detailList[0].startPrice = val;
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.params) {
      this.params = JSON.parse(this.$route.query.params);
    }
    if (this.$route.query.id) {
      this.params2.performanceId = this.$route.query.id;
      this.getDetail();
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    addList() {
      let length = this.form.detailList.length;
      let list = this.form.detailList;
      if (length) {
        list.forEach((item) => {
          if (item.deleteFlag == 1) {
            list = list.filter((item) => {
              return item.deleteFlag != 1;
            });
            length--;
          }
        });
        this.form.detailList.push({
          id: null,
          startPrice: list[length - 1].endPrice,
          endPrice: null,
          advancedCommission: null,
          deleteFlag: null
        });
      }
    },
    changeNum(val, index) {
      let before = this.form.detailList[index].startPrice; //每一项开始值
      // if(this.form.detailList[index + 1]){
      //   this.after = this.form.detailList[index + 1].endPrice //下一项结束值
      // }
      if (before >= Number(val)) {
        // this.errorNum = true
        this.form.detailList[index].endPrice = null;
        return this.$message.error("开始区间不能大于结束区间");
      }
      if (this.form.detailList[index + 1]) {
        this.form.detailList[index + 1].startPrice = val;
      }
    },
    del(index) {
      this.form.detailList[index].deleteFlag = 1;
    },
    async addSet() {
      //处理传过来的参数
      let old = { ...this.params };
      if (old.time && old.time.length) {
        old.startTime = old.time[0] + " " + "00:00:00";
        old.endTime = old.time[1] + " " + "00:00:00";
      }
      let adminList = [];
      if (old.peopleList && old.peopleList.length) {
        old.peopleList.forEach((element) => {
          adminList.push(element.id);
        });
      }
      if (old.departmentList) {
        old.departmentList = old.departmentList.map(Number);
      }

      let params = old;
      delete params.time;
      delete params.peopleList;
      if (adminList.length) {
        params.adminList = adminList;
      }

      //处理这个页面form参数
      params.criticalValue = this.form.criticalValue;
      params.basicCommission = this.form.basicCommission;
      params.detailList = this.form.detailList;
      if (!params.criticalValue) {
        return this.$message.error("请输入产能临界值");
      }
      if (!params.basicCommission) {
        return this.$message.error("请输入基础提成系数");
      }
      params.detailList.forEach((item) => {
        if(item.deleteFlag != 1) {
          if (!item.endPrice || !item.advancedCommission) {
            this.noNum = true;
          } else {
            this.noNum = false;
          }
        }
      });
      if (this.noNum) {
        return this.$message.error("请输入提成区间/区间系数");
      }

      if (this.$route.query.id) {
        params.clearRelationFlag = "1";
        const res = await edit(params);
        if (res.code == 200) {
          this.$message.success("设置成功");
        }
      } else {
        params.companyId = this.companyId;
        const res = await add(params);
        if (res.code == 200) {
          this.$message.success("设置成功");
        }
      }
      this.$router.push("/portrait/performance");
    },
    async getDetail() {
      let params = { ...this.params2 };
      const { data } = await detail(params);
      this.form.criticalValue = data.criticalValue;
      this.form.basicCommission = data.basicCommission;
      if (data.detailList && data.detailList.length) {
        this.form.detailList = [];
        data.detailList.forEach((item) => {
          this.form.detailList.push({
            id: item.id,
            startPrice: item.startPrice,
            endPrice: item.endPrice,
            advancedCommission: item.advancedCommission,
            deleteFlag: null
          });
        });
      }
      this.params.id = data.id;
      this.params.name = data.name;
      this.params.type = data.type;
      this.params.time = [data.startTime, data.endTime];
      this.params.departmentList = data.departmentList;
      this.params.adminList = data.adminList;
    },
    iptChange(val, t, index) {
      if (Number(val) < 0) {
        if (t == "a") {
          this.form.criticalValue = 0;
        } else if (t == "b") {
          this.form.basicCommission = 0;
        } else if (t == "c") {
          this.form.detailList[index].endPrice = 0;
        } else if (t == "d") {
          this.form.detailList[index].advancedCommission = 0;
        }
        return this.$message.error("请输入正数");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  text-align: left;
  display: flex;
  height: 100%;
  flex-direction: column;
  .bottom-tip {
    height: 92px;
    border-top: 1px solid #e6e6e6;
    display: flex;
    box-sizing: border-box;
    padding: 0 32px;
    align-items: center;
    div {
      color: #333;
      font-size: 14px;
      &:nth-of-type(1) {
        margin-right: 28px;
      }
    }
  }
  .a {
    color: #666;
    font-size: 20px;
    cursor: pointer;
  }
  .b {
    color: #666;
    font-size: 20px;
    margin: 0 16px;
  }
  .c {
    color: #333;
    font-size: 14px;
    margin-left: 48px;
  }
  .s {
    color: #101010;
    font-size: 24px;
  }
  .body {
    .head {
      margin: 24px 0 30px 66px;
      color: #333;
      font-size: 18px;
    }
    .y {
      margin-left: 16px;
    }
    .ipt {
      width: 96px;
      &:not(first-child) {
        margin-bottom: 24px;
      }
    }
    .zhi {
      margin: 0 16px;
    }
    .xi {
      margin: 0 16px 0 56px;
    }
    .del {
      margin-left: 8px;
      border: none;
      &:hover {
        background-color: transparent;
      }
    }
    .cp {
      padding: 8px 9px;
      border-color: #1890ff;
      color: #1890ff;
    }
  }
  .footer-container {
    width: 100%;
    height: 104px;
    border-top: 16px solid #eee;
    box-sizing: border-box;
    .btn-content {
      margin: 30px 32px 0 0;
      text-align: right;
    }
  }
  .form-overflow {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
